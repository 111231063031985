import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import tw from 'twin.macro'

import MdOutlineFileUpload from '../images/svg/MdOutlineFileUpload.svg'
import MdDeleteOutline from '../images/svg/MdDeleteOutline.svg'
import { loadFile, removeCachedFile, saveFile } from '../../src/data'

export default function Upload() {
  const [file, setFile] = useState()

  const onDrop = useCallback((acceptedFiles) => {
    const reader = new FileReader()

    reader.onload = () => {
      // Do whatever you want with the file contents
      const binaryStr = reader.result
      const enc = new TextDecoder('utf-8')
      const content = enc.decode(binaryStr)

      setFile({
        name: acceptedFiles[0].path,
        size: humanFileSize(acceptedFiles[0].size),
        content,
      })
      removeCachedFile()
    }
    reader.readAsArrayBuffer(acceptedFiles[0])
  }, [])

  useEffect(() => {
    const mount = async () => {
      // TODO: Loading
      const prevFile = await loadFile()

      setFile(prevFile)
    }
    mount()
  }, [])

  useEffect(() => {
    removeCachedFile()
    saveFile(file)
  }, [file])

  // TODO: Add styles/alert for rejected files
  const { getRootProps, getInputProps } = useDropzone({
    accept: '.json',
    maxFiles: 1,
    onDrop,
  })

  return (
    <div tw="w-full h-full flex-1 px-4 flex items-center justify-center">
      <div tw="w-full h-auto max-w-md px-6 py-8 bg-grey-4 bg-opacity-30 rounded-xl shadow-lg">
        <div
          {...getRootProps()}
          css={[
            tw`w-full h-60 p-4 flex flex-col justify-center items-center text-blue bg-grey-8 border-2 border-dashed border-grey-4 rounded-xl cursor-pointer`,
            tw`transition-colors ease-in-out focus-visible:(outline-none ring-2 ring-grey-3 ring-inset)`,
            !!file &&
              tw`border-opacity-60 bg-opacity-50 text-opacity-25 focus-visible:(ring-0)`,
          ]}
        >
          <div
            css={[
              tw`w-10 h-10 p-2 rounded-md bg-blue text-white`,
              !!file && tw`bg-opacity-25`,
            ]}
          >
            <MdOutlineFileUpload tw="w-full h-full" />
          </div>
          <input {...getInputProps({})} />
          <div tw="mt-6 font-bold">
            <span css={[tw`text-grey-2`, !!file && tw`text-opacity-25`]}>
              Drop your JSON file here, or{' '}
            </span>
            <span>browse</span>
          </div>
          <div tw="text-xs text-current">
            Your file will not leave this computer.
          </div>
        </div>

        {file && (
          <div tw="w-full transform h-20 mt-4 p-4 flex justify-between items-center bg-grey-8 text-grey-2 rounded-xl">
            <div tw="min-w-0">
              <div tw="text-grey-1 font-bold truncate">{file.name}</div>
              <div tw="text-xs text-blue truncate">{file.size}</div>
            </div>
            <button
              tw="ml-4 w-8 h-8 p-1 text-red hover:text-red-400 focus:outline-none focus-visible:(outline-none ring-2 ring-grey-3 ring-inset border-transparent) rounded"
              onClick={() => setFile()}
            >
              <MdDeleteOutline tw="w-full h-full" />
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

/**
 * Format bytes as human-readable text
 * @param bytes Number of bytes.
 * @return Formatted string.
 * @link https://stackoverflow.com/a/20732091
 */
function humanFileSize(bytes) {
  const units = ['B', 'kB', 'MB', 'GB']
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${units[i]}`
}
