import React from 'react'

import Layout from '../components/Layout'
import Upload from '../components/Upload'

export default function UploadPage() {
  return (
    <Layout>
      <Upload />
    </Layout>
  )
}
